import { Injectable, inject } from '@angular/core';

import {
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, of, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private r = inject(Router);

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const token: string | null = localStorage.getItem('token');
        if (token) {
            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = req.clone({
                headers: req.headers.set('X-AUTH-TOKEN', token),
            });

            // send cloned request with header to the next handler.
            return next.handle(authReq).pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('ERRORE', error);
                    if (error.status === 401) {
                        // Handle 401 error and redirect to login page.
                        localStorage.clear();
                        this.r.navigate(['/login'], {
                            preserveFragment: false,
                        });
                    }
                    return throwError(() => new Error('ERRORE'));
                })
            );
        } else {
            return next.handle(req).pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('ERRORE', error);
                    if (error.status === 401) {
                        // Handle 401 error and redirect to login page.
                        this.r.navigate(['/login'], {
                            preserveFragment: false,
                        });
                    }
                    return throwError(() => new Error('ERRORE'));
                })
            );
        }
    }
}
