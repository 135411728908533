import { Injectable, inject } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class LogUpdateService {
    private ac = inject(AlertController);
    private p = inject(Platform);

    constructor(private updates: SwUpdate) {}

    async manualCheck() {
        console.log('manualCheck');
        const update = await this.updates.checkForUpdate();
        console.log('update outcome', update);
        if (update) {
            this.askUpdate();
        } else {
            console.log('nessuna nuova versione');
        }
    }

    async checkForUpdates() {
        console.log('Checking for updates...');

        this.updates.versionUpdates.subscribe((event) => {
            const type = event.type;
            console.log('event', event);
            switch (type) {
                case 'NO_NEW_VERSION_DETECTED':
                    console.log('App up to date');
                    break;
                case 'VERSION_DETECTED':
                    console.log(
                        `Downloading new app version: ${event.version.hash}`
                    );

                    break;
                case 'VERSION_INSTALLATION_FAILED':
                    console.log(
                        `Failed to install app version '${event.version.hash}': ${event.error}`
                    );
                    break;
                case 'VERSION_READY':
                    console.log(
                        `Current app version: ${event.currentVersion.hash}`
                    );
                    console.log(
                        `New app version ready for use: ${event.latestVersion.hash}`
                    );
                    this.askUpdate();
                    break;
            }
        });
    }

    async askUpdate() {
        const a = await this.ac.create({
            header: 'Update available',
            message:
                'There is a new version of the app available. Would you like to update?',
            subHeader: 'Current  changes will be lost',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
                {
                    text: 'Update',
                    handler: () => {
                        document.location.reload();
                    },
                },
            ],
        });
        a.present();
    }
}
