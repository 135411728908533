import { Component, OnInit, inject } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Company, TFSRequest } from '@models';
import { CompaniesService } from '@services';
import { map } from 'rxjs';
import { LogUpdateService } from './services/update.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    private us = inject(LogUpdateService);

    ngOnInit(): void {
        this.us.checkForUpdates();

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.us.manualCheck();
            } else {
                console.log('App is not visible');
            }
        });
    }
}
